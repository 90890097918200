<template>
  <b-card-code
    title="Table Department"
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="3"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
            class="mr-1 mb-md-0"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(actions)="row">
        <!-- <b-button
          size="sm"
          class="mr-1"
          @click="info(row.item, row.index, $event.target)"
        >
          Info modal
        </b-button> -->
        <b-button
          variant="flat-warning"
          :to="{ name: 'department-edit', params: { id: row.item.id } }"
          class="text-nowrap"
        >
          <feather-icon icon="Edit3Icon" />
        </b-button><br>
        <b-button
          variant="flat-danger"
          class="text-nowrap"
          @click="deleteDepartmentData(row.item)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- <template #code>
      {{ codeKitchenSink }}
    </template> -->
  </b-card-code>
</template>

<script>
import axios from 'axios'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'
// import { codeKitchenSink } from './code'

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // { key: 'nij', label: 'Nomor Induk Jemaat', sortable: true },
        {
          key: 'name', label: 'Name',
        },
        {
          key: 'church.name', label: 'Church',
        },
        // {
        //   key: 'address', label: 'Address',
        // },
        // {
        //   key: 'abbreviation', label: 'Abbreviation',
        // },
        // {
        //   key: 'gender',
        //   label: 'Gender',
        //   formatter: value => (value === 1 ? 'Male' : 'Female'),
        // },
        // {
        //   key: 'date_of_birth', label: 'Date Of Birth',
        // },
        // {
        //   key: 'join_year', label: 'Join Year',
        // },
        // {
        //   key: 'profession', label: 'Profession',
        // },
        // {
        //   key: 'marriage_status', label: 'Marriage Status',
        // },
        // {
        //   key: 'date_of_marriage', label: 'Date Of Marriage',
        // },
        // {
        //   key: 'church.name', label: 'Church',
        // },
        // {
        //   key: 'departement_id', label: 'Departement Id',
        // },
        // {
        //   key: 'position_id', label: 'Position Id',
        // },

        { key: 'actions', label: 'Actions' },
      ],
      items: [],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      // codeKitchenSink,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getDepartmentData()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    editDepartmentData() {
      // eslint-disable-next-line no-alert
      alert('Coming Soon!')
    },

    getDepartmentData() {
      axios
        .get('/departments', {
          params: {
            id: this.$route.params.id,
          },
        })
        .then(res => {
          this.items = res.data.data
          // Set the initial number of items
          this.totalRows = this.items.length
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },

    deleteDepartmentData(rowData) {
      this.$swal({
        title: `Delete ${rowData.name}?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`/departments/${rowData.id}`).then(res => {
            if (res.status === 200) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getDepartmentData()
            } else {
              this.$swal({
                title: 'Error',
                text: 'Please contact admin',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your data is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
